
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Post from '@/components/Post.vue';
import NewsroomMixin from '@/mixins/newsroomMixin';

@Component({
  components: {
    Post,
  },
})
export default class Newsroom extends mixins(NewsroomMixin) {
  mounted() {
    this.getNewsHeadersAsync();
  }
}
